import * as React from "react";

import { AppFrontendProvider } from "../components/AppFrontendProvider/AppFrontendProvider";
import { DashboardHead } from "../components/Head/DashboardHead";
import NotFoundPage from "../components/NotFound/NotFound";
import { PublicRoute } from "../components/Routes/PublicRoute";
import { getTranslation } from "../helpers/getStaticProps";
import { TunnelLayout } from "../layouts/Tunnel/TunnelLayout";
import { ServerSideContext } from "../types/app";

const NotFound = () => {
  return <NotFoundPage />;
};

const NotFoundLayout = (page: React.ReactElement<unknown>): React.ReactNode => {
  return (
    <>
      {/* Nothing should wrap or block the Head component */}
      <DashboardHead />
      {/* router initialization is not working on 404 page, see: https://github.com/vercel/next.js/issues/35990 */}
      <AppFrontendProvider skipUserConsentModal={false}>
        <PublicRoute layout={TunnelLayout}>{page}</PublicRoute>
      </AppFrontendProvider>
    </>
  );
};

NotFound.getLayout = NotFoundLayout;

export const getStaticProps = async (context: ServerSideContext<null>) => {
  return {
    props: await getTranslation(context.locale),
  };
};

export default NotFound;
